import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Divider,
  ListItemSecondaryAction,
  Checkbox,
  MenuItem,
  FormHelperText,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import React, { useEffect, useState, useMemo, useRef } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import LocalSee from '@material-ui/icons/LocalSee';
import Search from '@material-ui/icons/Search';
import Check from '@material-ui/icons/Check';
import Add from '@material-ui/icons/Add';
import { getContactlist, addNewGroup, updateGroup, usersContactList, searchContact } from '../../../store/actions/contactActions';
import { withSnackbar } from 'notistack';
import FileBase64 from 'react-file-base64';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import CircularSpinner from '../../../component/CircularSpinner';

import PropTypes from 'prop-types';
import { makeStyles, styled } from '@material-ui/core/styles';
import { connect } from 'react-redux';

const currencies = [
  {
    value: 'all',
    label: 'All Contacts',
  },
  {
    value: 'contact',
    label: 'Contact',
  },
  {
    value: 'groups',
    label: 'Groups',
  },
];

const CloseButton = styled(IconButton)({
  position: 'absolute',
  right: 0,
  top: 0,
});

const DialogTitleStyled = styled(DialogTitle)({
  borderBottom: '1px solid #e0e0e0',
  padding: '10px 24px',
  marginBottom: 20,
});

const TypoPopHeadStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  fontSize: 18,
  fontWeight: '600',
}));

const SearchFieldStyled = styled(TextField)(({ theme }) => ({
  paddingTop: 10,
  paddingBottom: 10,
  '& div': { height: 50 },
}));

const ListItemStyled = styled(ListItem)(({ theme }) => ({
  borderRadius: 8,
}));

const AvatarShareStyled = styled(Avatar)({
  height: 50,
  width: 50,
  marginRight: 10,
});

const TypoListSubtext = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.secondary.contrastText,
}));

const ListStyled = styled(List)(({ theme }) => ({
  maxHeight: 550,
  overflow: 'auto',
  backgroundColor: '#f4f9fa',
  margin: '0 -24px',
  padding: '0 10px',
  marginTop: 30,
  paddingTop: 20,
}));

const ButtonSuccess = styled(Button)(({ theme }) => ({
  background: '#00d264',
  color: theme.palette.success.contrastText,
}));

const AvatarImageStyle = styled(Avatar)(({ theme }) => ({
  height: 140,
  width: 140,
  backgroundColor: '#e5e5e5',
}));

// const TextFieldStyled = styled(TextField)(({ theme }) => ({
//   '& div': {
//     height: 56,
//     padding: 0,
//     marginTop: 30,
//     textIndent: 14
//   }
// }));

const BoxAvtarStyled = styled(Box)(() => ({
  position: 'relative',
  height: 130,
  width: 130,
}));

const AvatarStyled = styled(Avatar)(() => ({
  height: 130,
  width: 130,
}));
const IconButtonStyles = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  position: 'absolute',
  right: 20,
  zIndex: 9,
  marginTop: 6,
}));

const IconAvtarButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  position: 'absolute',
  right: 0,
  zIndex: 9,
  bottom: 0,
  borderRadius: '60%',
  width: 32,
  height: 32,
  textAlign: 'center',
  lineHeight: '41px',
}));

const useStyles = makeStyles((theme) => ({
  nameMobile: {
    display: 'block',
    flexDirection: 'column',
  },
}));

const CreateNewGroup = (props) => {
  const classes = useStyles();
  const { user_id, business_code } = JSON.parse(localStorage.getItem('userData'));
  let formFields = {
    group_id: '',
    user_id,
    group_icon: '',
    group_members: '',
    group_name: '',
  };

  const {
    contactlist,
    getContactlist,
    loading,
    addNewGroup,
    updateGroup,
    usercontactlist,
    DetailView,
    success_message,
    enqueueSnackbar,
    grouplist,
    usersContactList,
    usersContactsList,
    totalContacts,
    searchContact
  } = props;
  const [values, setValues] = useState(formFields);

  const [checked, setChecked] = React.useState([]);
  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = useState(valueProp);
  const [ContactList, setContactList] = useState([]);
  const [addUsersId, setaddUsersId] = useState([]);
  const [is_private, setIsPrivate] = useState(0);
  const [memberchecked, setMemberchecked] = useState([]);

  const [searchvalue, setSearchvalue] = useState('');
  const searchedValue = useRef('')
  const [files, setFiles] = useState();
  const [image, setimage] = useState('');

  const [filesErr, setFilesErr] = useState('');
  const [checkErr, setCheckErr] = useState('');
  const [isAllMember, setIsAllMember] = useState(false);
  const [formvalidation, setFormvalidation] = useState({
    group_icon: '',
    group_members: '',
    group_name: '',
  });
  const [formvalues, setFormvalues] = useState(formFields);
  const [Loading, setLoading] = useState(false);
  const [ searchCalled , setSearchCalled ] = useState(true)
  const open1 = useRef(false); 
  const pageNm = useRef(1);
  console.log('contactlist*', contactlist);
  console.log('usercontactlist*', usercontactlist);
  const handleCancel = () => {
    open1.current =false
    setValue(false);
    onClose(!open);
    setContactList([]);
    pageNm.current = 1;
    setFilesErr('');
    setCheckErr('');
    const data = {
      group_icon: '',
      group_members: '',
      group_name: '',
    };
    setFormvalidation(data);
    const grouValues = {
      group_id: '',
      user_id,
      group_icon: '',
      group_members: '',
      group_name: '',
    };
    setValues(grouValues);
    setFormvalues(grouValues)
    setChecked([]);
    // setFilesErr('');
    setimage('');
    setIsAllMember(false)
  };
  console.log('values@',values)
  const getFiles = (files) => {
    // debugger
    setFilesErr('');
    var extn = files.name.split('.');
    var etn = ['jpg', 'jpeg', 'png'];

    var low = extn[1].toLowerCase();
    if (etn.includes(low)) {
      setFiles(files);
      setimage(files.base64);

      var image = files.base64.replace(/^data:image\/\w+;base64,/, '');
      setValues({ ...values, group_icon: image });
    } else {
      setFilesErr('This file not supportted');
      setValues({ ...values, group_icon: '' });
    }
  };
  
  console.log('searchvalue-',searchvalue)
  console.log('calling3', open1);
  useMemo(() => {
    setSearchvalue('');
    // setContactList(usercontactlist);
    if (open) {
    open1.current =true;
      setLoading(true);
      pageNm.current = 1;
      const dataToSend = {
        user_id,
        business_code,
        page: pageNm.current,
      };
      usersContactList(dataToSend);
      setTimeout(() => {
        let Ele = document.querySelector('#scrollElement');
        if (Ele) {
          var position = Ele.scrollTop;
          Ele.addEventListener('scroll', () => {
            if (Ele.offsetHeight + Ele.scrollTop >= Ele.scrollHeight && open1.current) {
              setLoading(true);
              if (searchedValue.current.length > 2 && searchCalled){
                console.log('Ele-');
                  // setContactList([])
                  setSearchCalled(false)
                  pageNm.current = pageNm.current + 1;
                  const dataToSend = {
                    keyword : searchedValue.current,
                    page : pageNm.current
                  };
                  searchContact(dataToSend)
              }else if (searchedValue.current.length === 0){

              pageNm.current = pageNm.current + 1;
              const dataToSend = {
                user_id,
                business_code,
                page: pageNm.current,
              };
              usersContactList(dataToSend);
              console.log('calling3-',pageNm, searchvalue);
            }
            }
          });
          // Ele.setAttribute('onscroll', 'fetchMoreData');
        }
      }, 1000);
    }
  }, [open]);
  console.log('pageNm-', pageNm);
  console.log('usersContactsList-', usersContactsList);
  useEffect(() => {
    if(open1.current){
      setLoading(false);
      if(usersContactsList.data !== undefined){
      setSearchCalled(true)
        setContactList([...ContactList, ...usersContactsList.data]);
      }
    }
    if (isAllMember) {
      const newChecked = [...checked];
      for( let value of ContactList){
      const currentIndex = checked.indexOf(value.user_id);
      if (currentIndex === -1 && !(newChecked.includes(value))) {
        newChecked.push(value.user_id);
      }
    }
      setChecked(newChecked);
      console.log('entered-',newChecked)
      values.group_members = newChecked.toString();
    }
  }, [usersContactsList]);
console.log('formFields--',formFields)
  useMemo(() => {
    if (DetailView !== null) {
      console.log('DetailView=',DetailView)
      setIsPrivate(DetailView.is_private);
      for (var x in DetailView) {
        values[x] = DetailView[x];
      }
      if (values.group_members) {
        setChecked(values.group_members.split(','));
      } else {
        setChecked([]);
      }
      setFormvalues(values)
    } else {
      setFiles('');
      setChecked([]);
      
      setValues(formFields);
    }
  }, [DetailView]);
  console.log('values==',values)
  useMemo(() => {
    success_message && enqueueSnackbar(success_message, { variant: 'success' });
  }, [success_message]);
  
  console.log('formvalues=',formvalues)
  const handleChange = (prop) => (e) => {
    // const form = { ...formvalues };
    // const formerr = { ...formvalidation };
    // form[event.target.name] = event.target.value;
    // formerr[event.target.name] = '';
    const formvalue = { ...formvalues };
    const formvalidations = { ...formvalidation };
    const fieldName = e.target.name;
    var fieldValue = '';
    formvalidations[fieldName] = '';
    // if (formvalue[fieldName] == '') {
    //   fieldValue = e.target.value
    // }
    formvalue[fieldName] = e.target.value;
    setValues(formvalue);
    console.log('valuess-',values)
    setFormvalidation(formvalidations);
  };
  const handleToggle = (value) => () => {
    setaddUsersId(value);
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      if (isAllMember) {
        setIsAllMember(!isAllMember);
      }
    }

    setChecked(newChecked);
    values.group_members = newChecked.toString();
    setCheckErr('');
  };
  console.log('checked-', checked);
  console.log('isAllMember-', isAllMember);
  const handleAllMembersToggle = (value) => {
    const newChecked = [...value];
    setChecked(newChecked);
    console.log('memberchecked-', memberchecked);
  };

  const handleSelectMember = () => {
    setIsAllMember(!isAllMember);
    //  console.warn('isAllMember111', isAllMember);
    if (!isAllMember) {
      filtered.map((data, i) => {
        if (memberchecked.indexOf(data.user_id) == -1) {
          memberchecked.push(data.user_id);
        }
      });
    } else {
      memberchecked.splice(0, memberchecked.length);
    }
    console.warn('memberchecked333', memberchecked);
    handleAllMembersToggle(memberchecked);
  };

  const handleSubmit = (e) => {
    // debugger

    e.preventDefault();
    const dataTosend = {
      user_id,
      business_code,
    };
    // console.log('checked====',checked);
    // return false;
    values.group_members = checked.toString();
    if (handlevalidation()) return false;
    console.log('values.group_id-',values.group_id)
    if (values.group_id !== '') {
      if (files == '') {
        const data_to_update = {
          group_id: values.group_id,
          group_members: checked.toString(),
          group_name: values.group_name.trim(),
          user_id,
          is_private,
        };
        // console.log('member--',checked)
        updateGroup(data_to_update, dataTosend);
      } else {
        const data_to_update = {
          group_icon: files.base64.replace(/^data:image\/\w+;base64,/, ''),
          group_id: values.group_id,
          group_members: checked.toString(),
          group_name: values.group_name.trim(),
          user_id,
          is_private,
        };
        updateGroup(data_to_update, dataTosend);
      }
    } else {
      if (values.group_name != '') {
        values.group_name = values.group_name.trim();
        if (values.group_name.length > 0) {
          if (files == '') {
            const data_to_update = {
              // group_id: values.group_id,
              group_members: checked.toString(),
              group_name: values.group_name.trim(),
              user_id,
              is_private,
              all_Contacts : isAllMember
            };

            console.log('data_to_update====', data_to_update);

            addNewGroup(data_to_update, dataTosend);
          } else {
            const data_to_update = {
              group_icon: files.base64.replace(/^data:image\/\w+;base64,/, ''),
              // group_id: values.group_id,
              group_members: checked.toString(),
              group_name: values.group_name.trim(),
              user_id,
              is_private,
            };
            addNewGroup(data_to_update, dataTosend);
          }
        }
      }
    }
    handleCancel();
    setIsAllMember(false);
  };

  const handleChangeCheck = (e) => {
    if (e.target.checked == 1) {
      setIsPrivate(1);
    } else {
      setIsPrivate(0);
    }
  };

  const handlevalidation = () => {
    // debugger
    let error = false;
    const formerr = { ...formvalidation };
    console.log(grouplist);
    // grouplist.map((item) => {
    //   if (item.group_name.toUpperCase() === values.group_name.toUpperCase()) {
    //     error = true;
    //     formerr.group_name = 'Group with this name is already added!';
    //     setFormvalidation(formerr);
    //   }
    // })
    // if (!values.group_icon) {
    //   error = true;
    //   setFilesErr('Group icon is required');
    // }

    // if (!values.group_members) {
    //   error = true;
    //   setCheckErr('Group members are required!');
    // }

    if (!values.group_name) {
      error = true;
      formerr.group_name = 'Group name is required!';
      setFormvalidation(formerr);
    }
    // debugger
    if (values.group_name.trim() == '') {
      error = true;
      formerr.group_name = 'Please enter valid Group name';
      setFormvalidation(formerr);
    }

    return error;
  };
  useEffect(() => {
    const dataTosend = {
      user_id,
      business_code,
    };
    getContactlist(dataTosend);
    // setContactList(usercontactlist);
  }, []);
  
  const [currency, setCurrency] = React.useState('all');
  const handlesearch = (e) => {
    console.log('e.target.value',e.target.value)
    setSearchvalue(e.target.value);
    searchedValue.current = e.target.value
    if (e.target.value.length > 2 && searchCalled){
      setSearchCalled(false)
      setLoading(true);
      setContactList([])
      pageNm.current = 1
      const dataToSend = {
        keyword : e.target.value,
        page : pageNm.current
      };
      searchContact(dataToSend)
    }
    else if (e.target.value === ''){
      setLoading(true);
      setContactList([])
      pageNm.current = 1;
      const dataToSend = {
        user_id,
        business_code,
        page: pageNm.current,
      };
      usersContactList(dataToSend);
    }
    
    // let valueToSearch = e.target.value
    // let filteredByname = [];
    // if (e.target.value !== ''){
    //   filteredByname = ContactList.filter(
    //     (suggestion1) =>
    //     suggestion1.first_name.toLowerCase().indexOf(valueToSearch.toLowerCase()) > -1 ||
    //     suggestion1.last_name.toLowerCase().indexOf(valueToSearch.toLowerCase()) > -1 ||
    //     suggestion1.mobile_no.toLowerCase().indexOf(valueToSearch.toLowerCase()) > -1
    //     );
    //   }else{
    //     filteredByname = ContactList
    //     setContactList(filteredByname);

    // }

    // if (filteredByname.length == 0) {
    //   filteredByname.push({
    //     user_id: 0,
    //     first_name: 'No record found',
    //     last_name: '',
    //     profile_image: '',
    //   });
    // }
    // setContactList(filteredByname);
    setChecked(values.group_members.split(','));
  };
  console.log('ContactList=====', ContactList);
  let filtered = [];
  if (ContactList.length > 0) {
    filtered = ContactList.filter((a) => a.status == 1);
  }
  console.log('filtered=====', filtered);
  const Rymindrlist =
    filtered.length > 0 ? (
      filtered.map((item) => (
        <>
          <ListItemStyled key={value} button onClick={handleToggle(item.user_id)} id="create-update-group-list" >
            <ListItemAvatar>
              {item.user_id !== 0 ? <AvatarShareStyled alt="semy Sharp" src={item.profile_image} /> : null}
            </ListItemAvatar>
            <ListItem  className={classes.nameMobile} >
              <ListItemText
                primary={
                  item.first_name
                    ? item.first_name + ' ' + item.last_name
                    : item.group_name
                    ? item.group_name
                    : item.mobile_no
                    ? item.mobile_no
                    : ''
                }
              />
              <ListItemText primary={item.mobile_no} />
            </ListItem>
            <ListItemSecondaryAction>
              {item.user_id !== 0 ? (
                <Checkbox
                  edge="end"
                  onChange={handleToggle(item.user_id)}
                  checked={checked.indexOf(item.user_id) !== -1}
                  style={{ marginRight: 0, color: '#17baff' }}
                />
              ) : (
                ''
              )}
            </ListItemSecondaryAction>
          </ListItemStyled>
          <Divider variant="inset" component="li" />
        </>
      ))
    ) : (
      <h6 style={{ textAlign: 'center' }}>No Contacts Found</h6>
    );
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="confirmation-dialog-title"
        open={open}
        {...other}
      >
        <DialogTitleStyled id="form-dialog-title">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <TypoPopHeadStyled>Create group</TypoPopHeadStyled>
            <IconButton color="default" onClick={handleCancel}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitleStyled>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={3} justifyContent="center">
                <BoxAvtarStyled>
                  <AvatarStyled alt="Remy Sharp" src={image || values.group_icon} />
                  <FormControlLabel
                    className="attachement"
                    id="icon-button-file"
                    control={
                      <FileBase64
                        accept="image/*"
                        name="group_icon"
                        onDone={getFiles}
                        onChange={handleChange('group_icon')}
                        className="displayNone"
                        style={{ display: 'none' }}
                      />
                    }
                    label={
                      <IconAvtarButton color="primary" aria-label="upload picture" component="span">
                        <PhotoCamera
                          color="inherit"
                          fontSize="small"
                          style={{ fontSize: '19px', position: 'relative', bottom: '4px' }}
                        />
                      </IconAvtarButton>
                    }
                  />
                  <FormControl error={!!filesErr}>
                    <FormHelperText> {filesErr} </FormHelperText>
                  </FormControl>
                </BoxAvtarStyled>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  id="outlined-basic"
                  label="Group Name"
                  variant="outlined"
                  onChange={handleChange('group_name')}
                  value={values.group_name}
                  name="group_name"
                  error={formvalidation.group_name !== ''}
                  helperText={formvalidation.group_name}
                  fullWidth
                />
              </Grid>
            </Grid>
            <SearchFieldStyled
              id="input-with-icon-textfield"
              variant="outlined"
              fullWidth
              size="small"
              value={searchvalue}
              onChange={handlesearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
            {console.log('private -', is_private)}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox checked={is_private} onChange={handleChangeCheck} name="isPrivate" color="primary" />
                }
                label="Private Group."
                style={{ marginTop: '1rem' }}
              />
              <div style={{ position: 'relative', bottom: '10px', left: '30px' }}>
                <p>Checking this will ensure your mobile contacts will not be able to add themselves to this group.</p>
              </div>
            </Grid>
            {/* <Grid item xs={8}>
                <FormControl error={!!checkErr}>
                  <FormHelperText>{checkErr} </FormHelperText>
                </FormControl>
              </Grid> */}
            <Grid item xs={12}>
              <ListItem button>
                <ListItemAvatar />
                <ListItemText primary={`Select All ( ${totalContacts !== undefined ? totalContacts : 0} )`} />
                <ListItemSecondaryAction>
                  <Checkbox
                    edge="end"
                    onChange={handleSelectMember}
                    checked={isAllMember}
                    // inputProps={{ 'aria-labelledby': labelId }}
                    color="primary"
                  />
                </ListItemSecondaryAction> 
              </ListItem>
            </Grid>

            <IconButtonStyles aria-label="delete" onClick={handleSubmit}>
              <Check />
            </IconButtonStyles>
            <ListStyled style={{ background: 'primary' }} id="scrollElement">
              {Rymindrlist}
            </ListStyled>
            {Loading ? (
              <Box sx={{ width: 'fit-content', margin: 'auto' }}>
                <CircularProgress
                  variant="indeterminate"
                  disableShrink
                  sx={{
                    color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
                    animationDuration: '550ms',
                    [`& .${circularProgressClasses.circle}`]: {
                      strokeLinecap: 'round',
                    },
                  }}
                  size={30}
                  thickness={4}
                  {...props}
                />
              </Box>
            ) : (
              <div style={{ minHeight: 40, width: 'auto' }}></div>
            )}
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.contact.loading,
    error: state.contact.error,
    contactlist: state.contact.contactlist,
    contactGroupMemberlist: state.contact.contactGroupMemberlist,
    grouplist: state.contact.grouplist,
    usercontactlist: state.contact.usercontactlist,
    groupdeletemessage: state.contact.groupdeletemessage,
    success_message: state.contact.success_message,
    usersContactsList: state.contact.usersContactsList,
    totalContacts: state.contact.totalContacts,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getContactlist: (data) => dispatch(getContactlist(data)),
    addNewGroup: (data, dataTosend) => dispatch(addNewGroup(data, dataTosend)),
    updateGroup: (data, dataTosend) => dispatch(updateGroup(data, dataTosend)),
    usersContactList: (data) => dispatch(usersContactList(data)),
    searchContact: (data) => dispatch(searchContact(data))
  };
};
CreateNewGroup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  getContactlist: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  addNewGroup: PropTypes.func.isRequired,
  updateGroup: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  success_message: PropTypes.any.isRequired,
};
export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(CreateNewGroup));
